import React, { useCallback, useEffect, useState } from 'react';
import { selectDashboardData } from 'app/store/userSlice';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import history from '@history';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import businessData from '../query/business';
import quickChatQuery from '../query/quickChat';
import {
  callPendingTaskFunc,
  fetchSocialConnections,
  handleApiRequest,
  handleFBLogin,
  handleSocialResponse,
} from '../common/common';
import SelectPlatformBox from '../business/quick-social/SelectPlatformBox';

const CommonPendingTask = (props) => {
  const [cardsData, setCardsData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [facebookUserId, setFacebookUserId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;
  const [socialPlatformId, setSocialPlatformId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { productType, bgColor, buttonColor, videoImg, botLink } = props;

  const settings = {
    dots: false,
    infinite: cardsData?.data?.appSetupCard?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const getCards = useCallback(async () => {
    try {
      const payloadData = {
        query: businessData.getAppSetupCard,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          type: productType,
        },
      };
      const resultData = await handleApiRequest(payloadData);

      if (resultData?.getAppSetupCard?.status === 200) {
        setCardsData(resultData?.getAppSetupCard);
      } else {
        console.error('Unexpected status:', resultData?.getAppSetupCard?.status);
      }
    } catch (error) {
      console.error('Error fetching cards data:', error);
    }
  }, [productType, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getCards();
    }
  }, [getCards, userCurrentBusinessDetails?.id]);

  const handleCallFunction = async (cardName) => {
    try {
      const payload = {
        query: quickChatQuery.updateAppSetUpCard,
        variables: {
          callFromQuickHubDashboard: false,
          appSetUpCardId: Number(cardsData?.appSetUpCardId),
          cardType: cardName,
          productType,
        },
      };

      const res = await handleApiRequest(payload);
      if (res?.updateAppSetUpCard?.status === 200) {
        getCards();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const redirect = (type) => {
    switch (type) {
      case 'pending-item':
        history.push(`/pending-task`, { productType, botLink });
        break;
      default:
        console.warn('Unknown redirect type:', type);
    }
  };

  const openVideoUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('No URL provided');
    }
  };

  const handleClick = async (item) => {
    const socialPlatformIds = item?.cardName === 'connectYourInstagram' ? 10 : 9;
    setSocialPlatformId(socialPlatformIds);
    if (socialPlatformIds) {
      setLoading(true);
      try {
        let authResponse;
        if (item?.cardName === 'connectYourInstagram' || 'connectYourFacebook') {
          authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, socialPlatformIds);
          if (authResponse) {
            const fbAccessToken = authResponse?.accessToken;
            setFacebookUserId(authResponse?.userID);

            if (fbAccessToken && userCurrentBusinessDetails?.id) {
              await fetchSocialConnections(
                socialPlatformIds,
                userCurrentBusinessDetails,
                setConnectionData,
                setAccessToken,
                setOpenDialog,
                setSelectedPage,
                setLoading,
                fbAccessToken
              );
            } else {
              console.log('Missing Facebook access token or business details.');
            }
          } else {
            console.log('Facebook login failed or was cancelled.');
          }
        }
      } catch (error) {
        console.error('Error during social connection:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    cardsData?.data?.filter((item) => !item?.isAdded).length > 0 && (
      <div
        className={`p-10 sm:p-16 md:px-0 w-full flex gap-10 md:gap-0 flex-col md:flex-row justify-center !bg-${bgColor} rounded-md`}
      >
        <div className="w-full md:w-1/6 flex flex-col justify-center">
          <div className="w-full flex flex-col gap-5 items-start">
            <div className="mx-auto bg-white rounded-full flex items-center justify-center w-60 h-60 lg:w-88 lg:h-88">
              <h1 className={`text-48 sm:text-64 font-500 text-${bgColor}`}>
                {cardsData?.data?.filter((item) => !item?.isAdded).length}
              </h1>
            </div>
            <span className="mx-auto text-16 lg:text-20 font-bold text-white">
              {t('quickHubDashboard.pendingTask')}
            </span>
          </div>
        </div>
        <div className="w-full md:w-5/6 mr-0 md:mr-16">
          <div className=" bg-white rounded-md p-10 sm:p-20">
            <div className="flex items-center mb-10 justify-between">
              <p className="text-14 sm:text-16 font-semibold">{t('quickHubDashboard.nextTask')}</p>
              <Button
                className={`h-0 font-semibold cursor-pointer hover:bg-transparent text-${bgColor} text-14 `}
                size="small"
                onClick={() => redirect('pending-item')}
              >
                {t('businessDashboard.seeAll')}
              </Button>
            </div>
            <div
              className={`border-1 border-solid  w-full rounded-md p-6 sm:p-10 !border-${bgColor}`}
            >
              <Slider {...settings}>
                {cardsData?.data
                  ?.filter((item) => !item?.isAdded)
                  .map((item, index) => (
                    <div className="flex" key={index}>
                      <div className="flex flex-col gap-10 sm:gap-0 sm:flex-row justify-between items-center">
                        <p className="text-14 sm:text-16 font-medium">{item?.title}</p>
                        <div className="flex gap-14 items-center">
                          {item?.videoUrl && (
                            <Button
                              className="text-12 sm:text-16 font-medium  disabled:text-black rounded-md"
                              variant="outlined"
                              color={buttonColor}
                              type="button"
                              size="small"
                              onClick={() => openVideoUrl(item?.videoUrl)}
                            >
                              <img
                                src={videoImg}
                                alt="good"
                                className="max-w-60 sm:max-w-80 mr-10"
                              />
                              {t('quickHubDashboard.watchVideo')}
                            </Button>
                          )}
                          {item?.buttonColor && item?.backgroundColor && (
                            <Button
                              style={{
                                backgroundColor: item?.buttonColor,
                              }}
                              className="text-12 sm:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md"
                              variant="contained"
                              color={buttonColor}
                              type="button"
                              size="small"
                              onClick={() => {
                                if (
                                  item?.cardName === 'connectYourInstagram' ||
                                  item?.cardName === 'connectYourFacebook'
                                ) {
                                  handleClick(item);
                                } else {
                                  callPendingTaskFunc(
                                    item,
                                    productType,
                                    handleCallFunction,
                                    userCurrentBusinessDetails,
                                    botLink,
                                    bgColor
                                  );
                                }
                              }}
                            >
                              {item?.buttonAction}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        {openDialog && (
          <SelectPlatformBox
            onClose={handleClose}
            openDialog={openDialog}
            socialPlatformId={socialPlatformId}
            connectionData={connectionData}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            setOpenDialog={setOpenDialog}
            handleSocialResponse={() =>
              handleSocialResponse(
                socialPlatformId,
                accessToken,
                facebookUserId,
                selectedPage,
                userCurrentBusinessDetails,
                setLoading,
                enqueueSnackbar,
                setConnectionData,
                setOpenDialog
              )
            }
            handleClose={handleClose}
          />
        )}
      </div>
    )
  );
};

export default CommonPendingTask;
